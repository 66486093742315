import React from "react"
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "../utils/auth"
import { Row, Col, Form, Button  } from "react-bootstrap"

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = { tusername: ``,        password: ``, };

        this.handleUpdate = (event) => {
            this.setState({
                [event.target.name]: event.target.value,
            })
        } 
        this.handleSubmit = (event) =>{
            event.preventDefault()
            handleLogin(this.state)
        }
    }
    render() {
        if (isLoggedIn()) {
            navigate(`/portfolio-secret/`)
        }
        return (
            <>
            <Row>
                
                <Col md={6} className = "offset-3 mt-5 mb-5">
                <Form
                    className="part-contact"
                    method="post"
                    onSubmit={event => {
                    this.handleSubmit(event);
                    navigate(`/login/`);
                    }}
                >
                    <Row className="mb-3">
                    <Form.Group as={Col} md={12} className="mb-4" controlId="formGridPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                        type="password"
                        name="password"
                        onChange={this.handleUpdate}
                        placeholder="Password"
                        required
                        />
                    </Form.Group>
                    <Col xs={12} className="text-right">
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                    </Col>
                    </Row>
                    <Row>
                    <Col>
                       
                    </Col>
                    </Row>
                </Form>
                </Col>
            </Row>
        </>
        )
    }
}

export default Login